.Welcome {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f5f5ef;
}

.Welcome-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.Welcome-container section {
  width: 100%;
  padding: 20px;
  max-width: 480px;
}

.Welcome-container section p {
  text-align: left;
}
